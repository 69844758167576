import { gql } from '@apollo/client';

export const TASKLIST_COUNT = gql`
  query taskListCount($users: [Int!]) {
    tasks: tasks_task_aggregate(
      where: {
        assigned_to_id: { _in: $users }
        completed: { _eq: false }
        due_date: { _gte: "now()" }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const TASKLIST = (queryName, parlor) => gql`
${queryName} taskList($users: [Int!], $parlor: Int, $completed: Boolean, $from: date, $to: date, $status: String,       $start: timestamptz
  $end: timestamptz, $role: String) {
  tasks: tasks_task(where: {
    ${
      parlor
        ? 'accountsUserByAssignedToId:{accounts_profile:{accounts_profile_parlors:{parlor_id: {_eq: $parlor}}}},'
        : ''
    }
    assigned_to_id: {_in: $users},
      status: {_eq: $status},
      due_date: {_gte: $from, _lte: $to},
      finally_moderated_at: {_gte: $start, _lte: $end},
      completed: {_eq: $completed},
      _or: [
        {accountsUserByAssignedToId: {role: {_eq: $role}}},
        {assigned_to_id: {_is_null: true}}
      ]
    }) {
    id
    responsible_user: accountsUserByResponsibleUserId{
      id
      first_name
      middle_name
      last_name
    }
    assigned_to: accountsUserByAssignedToId {
      id
      first_name
      middle_name
      last_name
      role
      profile: accounts_profile {
        id
        profile_parlors: accounts_profile_parlors {
          id
          parlor: core_parlor {
            id
            name
          }
        }
      }
      department: core_department {
        id
        name
      }
    }
    comment:tasks_comments {
      id
    }
    finally_moderated_at
    title
    status
    note
    moderated_at
    completed
    completed_date
    created_date
    due_date
    points
    priority
    created_by: accounts_user {
      id
      first_name
      middle_name
      last_name
    }
  }
}
`;

export const DEPARTMENTS = gql`
  query departments($isEstimation: Boolean) {
    departments: core_department(where: { is_estimation: { _eq: $isEstimation } }) {
      id
      name
    }
  }
`;

export const USERS = (parlor) => gql`
query Users($departments: [Int!], $date: date ${parlor ? `, $parlor: Int` : ''}) {
    users: accounts_user(where: {
        ${
          parlor ? `accounts_profile: {accounts_profile_parlors: {parlor_id: {_eq: $parlor}}},` : ''
        }
        role: {_nin: ["student"]},
        department_id: {_in: $departments},
        is_active: {_eq: true},
        temporary_blocked: {_eq: false}
    })
        {
            id
            first_name
            last_name
            middle_name
            role
            department_id
            profile: accounts_profile {
              id
              efficiency_ratio
              rank
              parlors: accounts_profile_parlors {
                id
                parlor_id
              }
            }
        }
        departments: core_department {
            id
            name
        }
  }
`;

export const fetchComplexityTask = gql`
  query fetchComplexityTask {
    complexityTask: tasks_complexitytask {
      id
      percent
      complexity
      comment
    }
  }
`;

export const fetchEmployeeOnboardingTask = gql`
  query fetchEmployeeOnboardingTask {
    employeeOnboardingTask: tasks_employeeonboardingtask(
      where: { is_active: { _eq: true }, status: { _eq: "template_task" } }
    ) {
      id
      note
      points
      due_date
      roles
      title
      assigned_tos: tasks_employeeonboardingtask_assigned_tos {
        assigned_to: accounts_user {
          first_name
          last_name
          id
        }
      }
      responsible_user: accounts_user {
        first_name
        last_name
        id
      }
    }
  }
`;

export const fetchFreeTask = gql`
  query fetchFreeTask {
    tasks: tasks_task(where: { is_free_task: { _eq: true } }) {
      id
      title
      due_date
      created_date
      responsible_user: accountsUserByResponsibleUserId {
        id
        first_name
        middle_name
        last_name
      }
      points
      created_by: accounts_user {
        id
        first_name
        last_name
      }
    }
  }
`;

export const fetchFreeTaskCount = gql`
  query fetchFreeTaskCount {
    tasksFreeCount: tasks_task_aggregate(where: { is_free_task: { _eq: true } }) {
      aggregate {
        count
      }
    }
  }
`;
