import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import 'src/components/Forms/TipTapEditor/styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBold,
  faItalic,
  faStrikethrough,
  faCode,
  faRemoveFormat,
  faH,
  faParagraph,
  faFileCode,
  faRulerHorizontal,
  faArrowLeft,
  faArrowCircleLeft,
  faArrowCircleRight,
  faListDots,
  faListNumeric,
} from '@fortawesome/free-solid-svg-icons';
import React, { useEffect } from 'react';

const MenuBar = ({ editor }) => {
  return (
    <div className='toolabr-container'>
      <div className='toolbar'>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '0.5rem',
          }}
        >
          <div className='toolbar__section'>
            <div
              className={'icon ' + (editor.isActive('bold') ? 'is-active' : '')}
              onClick={() => editor.chain().focus().toggleBold().run()}
            >
              <FontAwesomeIcon icon={faBold} />
            </div>
            <div
              className={'icon ' + (editor.isActive('italic') ? 'is-active' : '')}
              onClick={() => editor.chain().focus().toggleItalic().run()}
            >
              <FontAwesomeIcon icon={faItalic} />
            </div>
            <div
              className={'icon ' + (editor.isActive('strike') ? 'is-active' : '')}
              onClick={() => editor.chain().focus().toggleStrike().run()}
            >
              <FontAwesomeIcon icon={faStrikethrough} />
            </div>
            <div
              className={'icon ' + (editor.isActive('code') ? 'is-active' : '')}
              onClick={() => editor.chain().focus().toggleCode().run()}
            >
              <FontAwesomeIcon icon={faCode} />
            </div>
            <div
              className={'icon ' + (editor.isActive('codeBlock') ? 'is-active' : '')}
              onClick={() => editor.chain().focus().toggleCodeBlock().run()}
            >
              <FontAwesomeIcon icon={faFileCode} />
            </div>
            <div className='icon' onClick={() => editor.chain().focus().setHorizontalRule().run()}>
              <FontAwesomeIcon icon={faRulerHorizontal} />
            </div>
            <div
              className='icon'
              onClick={() => editor.chain().focus().unsetAllMarks().clearNodes().run()}
            >
              <FontAwesomeIcon icon={faRemoveFormat} />
            </div>
            <div className='icon' onClick={() => editor.chain().focus().setParagraph().run()}>
              <FontAwesomeIcon icon={faParagraph} />
            </div>
          </div>

          <div className='toolbar__section'>
            <div
              className={'icon ' + (editor.isActive('heading', { level: 1 }) ? 'is-active' : '')}
              onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
            >
              <FontAwesomeIcon icon={faH} />1
            </div>
            <div
              className={'icon ' + (editor.isActive('heading', { level: 2 }) ? 'is-active' : '')}
              onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
            >
              <FontAwesomeIcon icon={faH} />2
            </div>
            <div
              className={'icon ' + (editor.isActive('heading', { level: 3 }) ? 'is-active' : '')}
              onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
            >
              <FontAwesomeIcon icon={faH} />3
            </div>
            <div
              className={'icon ' + (editor.isActive('heading', { level: 4 }) ? 'is-active' : '')}
              onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
            >
              <FontAwesomeIcon icon={faH} />4
            </div>
            <div
              className={'icon ' + (editor.isActive('heading', { level: 5 }) ? 'is-active' : '')}
              onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}
            >
              <FontAwesomeIcon icon={faH} />5
            </div>
            <div
              className={'icon ' + (editor.isActive('heading', { level: 6 }) ? 'is-active' : '')}
              onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}
            >
              <FontAwesomeIcon icon={faH} />6
            </div>
            <div
              className={'icon ' + (editor.isActive('bulletList') ? 'is-active' : '')}
              onClick={() => editor.chain().focus().toggleBulletList().run()}
            >
              <FontAwesomeIcon icon={faListDots} />
            </div>
            <div
              className={'icon ' + (editor.isActive('orderList') ? 'is-active' : '')}
              onClick={() => editor.chain().focus().toggleOrderedList().run()}
            >
              <FontAwesomeIcon icon={faListNumeric} />
            </div>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '0.5rem',
          }}
        >
          <div className='toolbar__section'>
            <div className='icon' onClick={() => editor.chain().focus().undo().run()}>
              <FontAwesomeIcon icon={faArrowCircleLeft} />
            </div>
            <div className='icon' onClick={() => editor.chain().focus().redo().run()}>
              <FontAwesomeIcon icon={faArrowCircleRight} />
            </div>
          </div>

          <div className='toolbar__section'>
            <div
              className='icon'
              onClick={() => editor.chain().focus().setHardBreak().run()}
              style={{ width: '100%' }}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const TiptapEditor = ({ onContentUpdate, contentValue }) => {
  const editor = useEditor({
    extensions: [StarterKit],
    content: contentValue ?? '',
    editable: true,
    editorProps: {
      attributes: {
        spellcheck: 'false',
      },
    },
    onUpdate: ({ editor }) => {
      if (onContentUpdate) {
        onContentUpdate(editor.getHTML());
      }
    },
  });

  useEffect(() => {
    editor.commands.setContent(contentValue);
  }, [editor, contentValue]);

  return (
    <div className='tiptap'>
      <MenuBar editor={editor} />
      <EditorContent editor={editor} className='editor-content mt-3' />
    </div>
  );
};

export default TiptapEditor;
