import React, { useCallback, useContext, useState } from 'react';
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Field, Formik } from 'formik';
import { Input as FormikInput, Select as FormikSelect } from './Forms/FormikComponents';
import { POINTS } from 'helpers/enum.helpers';
import { useToggle } from 'hooks/gql.hooks';
import * as Yup from 'yup';
import { useQuery, gql } from '@apollo/client';
import { handleAxiosErrorSwal, isRole, CanvaBuilt } from 'helpers';
import { useToast } from 'services/notifications.service';
import { UserContext } from 'src/context.jsx';
import { useTranslation } from 'react-i18next';
import { useDepartments } from 'views/Tasks/Components';

export const CategoryPointDepartmentAdd = ({ children }) => {
  const departments = useDepartments(false, true);
  const [modal, toggleModal] = useToggle(false);
  const toast = useToast();
  const { api_v2, user } = useContext(UserContext);
  const { t } = useTranslation();
  const [isPresentationSalon, SetIsPresentationSalon] = useState(false);
  const toggleisPresentationSalon = () => SetIsPresentationSalon(!isPresentationSalon);

  const schema = Yup.object().shape({
    department: Yup.object().required(t('form.required')),
    point: Yup.object().required(t('form.required')),
    comment: Yup.string().required(t('form.required')),
  });

  const defaultValues = {
    department: [],
    point: [],
    comment: '',
  };

  const handleCheck = useCallback(
    async (values, actions) => {
      const formData = {
        department: values.department.value,
        point: values.point.value,
        comment: values.comment,
      };

      const handleSuccess = () => {
        toast.add(t('swal.success'), 'success');
        actions.resetForm(defaultValues);
        toggleModal();
      };

      try {
        await api_v2.post(`/core/department-point/`, formData);

        handleSuccess();
      } catch (error) {
        handleAxiosErrorSwal(error);
      }
    },
    [api_v2, toast, toggleModal, defaultValues],
  );

  const renderCategoryPointDepartmentAdd = () => {
    if (isRole(['master'], user)) {
      return null;
    }

    return (
      <>
        {React.cloneElement(children, { onClick: toggleModal })}
        <Modal isOpen={modal} toggle={toggleModal}>
          <Formik initialValues={defaultValues} validationSchema={schema} onSubmit={handleCheck}>
            {(form) => (
              <Form onSubmit={form.handleSubmit} autoComplete='new-forma'>
                <ModalHeader
                  toggle={() => {
                    form.resetForm(defaultValues);
                    toggleModal();
                  }}
                >
                  {t('extra.mark_depart')}
                </ModalHeader>
                <ModalBody style={{ marginTop: '15px' }}>
                  <Button
                    color='primary'
                    style={{
                      fontSize: '12px',
                      padding: '10px',
                      marginBottom: '30px',
                      width: '100%',
                    }}
                    onClick={toggleisPresentationSalon}
                  >
                    {t('extra.mark_instruction')}
                  </Button>
                  <Modal size='lg' toggle={toggleisPresentationSalon} isOpen={isPresentationSalon}>
                    <CanvaBuilt code='DAF1ZcGfXNc' />
                  </Modal>
                  <Field
                    name='department'
                    label={t('parlor_points.department')}
                    options={departments}
                    component={FormikSelect}
                  />
                  <Field
                    name='point'
                    label={t('parlor_points.choose_mark')}
                    options={POINTS}
                    component={FormikSelect}
                  />
                  <Field
                    name='comment'
                    component={FormikInput}
                    placeholder={t('parlor_points.comment')}
                  />
                </ModalBody>
                <ModalFooter>
                  <Button color='primary' type='submit'>
                    {t('shop_detail.send')}
                  </Button>
                  <Button
                    color='secondary'
                    type='button'
                    onClick={() => {
                      form.resetForm(defaultValues);
                      toggleModal();
                    }}
                  >
                    {t('buttons.close')}
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </Modal>
      </>
    );
  };

  return renderCategoryPointDepartmentAdd();
};

export const CategoryPointDepartmentDirectorReport = ({ children }) => {
  const departments = useDepartments(false, true);
  const [load, setLoad] = useState(false);
  const [modal, toggleModal] = useToggle(false);
  const toast = useToast();
  const { api_v2, user } = useContext(UserContext);
  const { t } = useTranslation();

  const schema = Yup.object().shape({
    department: Yup.object().required(t('form.required')),
  });

  const defaultValues = {
    department: null,
    point_from_report: null,
    point_from_director: null,
  };

  const handleCheck = useCallback(
    async (values, actions) => {
      setLoad(true);
      const formData = {
        ...values,
        department: values.department.value,
      };

      const handleSuccess = () => {
        setLoad(false);
        toast.add(t('swal.success'), 'success');
        actions.resetForm(defaultValues);
        toggleModal();
      };

      try {
        await api_v2.post(`/core/department-point/`, formData);
        handleSuccess();
      } catch (error) {
        setLoad(false);
        handleAxiosErrorSwal(error);
      }
    },
    [api_v2, toast, toggleModal, defaultValues],
  );

  const renderCategoryPointDepartmentAdd = () => {
    if (!isRole(['owner'], user)) {
      return null;
    }

    return (
      <>
        {React.cloneElement(children, { onClick: toggleModal })}
        <Modal isOpen={modal} toggle={toggleModal}>
          <Formik initialValues={defaultValues} validationSchema={schema} onSubmit={handleCheck}>
            {(form) => (
              <Form onSubmit={form.handleSubmit} autoComplete='new-forma'>
                <ModalHeader
                  toggle={() => {
                    form.resetForm(defaultValues);
                    toggleModal();
                  }}
                >
                  {t('extra.mark_depart_dir')}
                </ModalHeader>
                <ModalBody style={{ marginTop: '15px' }}>
                  <Field
                    name='department'
                    label={t('parlor_points.department')}
                    options={departments}
                    component={FormikSelect}
                  />

                  <Field
                    name='point_from_report'
                    label={t('extra.mark_report')}
                    type='number'
                    component={FormikInput}
                  />
                  <Field
                    name='point_from_director'
                    label={t('extra.dir_mark')}
                    type='number'
                    component={FormikInput}
                  />
                </ModalBody>
                <ModalFooter>
                  <Button color='primary' type='submit' disabled={load}>
                    {t('shop_detail.send')}
                  </Button>
                  <Button
                    color='secondary'
                    type='button'
                    onClick={() => {
                      form.resetForm(defaultValues);
                      toggleModal();
                    }}
                  >
                    {t('buttons.close')}
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </Modal>
      </>
    );
  };

  return renderCategoryPointDepartmentAdd();
};
