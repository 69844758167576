import React from 'react';
import PropTypes from 'prop-types';

const HorizontalDivider = ({ width, color, height, margin }) => {
  const style = {
    width: width || '100%',
    height: height || '1px',
    backgroundColor: color || 'gray',
    margin: margin || '2rem 0',
  };

  return <div style={style} />;
};

HorizontalDivider.propTypes = {
  width: PropTypes.string,
  color: PropTypes.string,
  height: PropTypes.string,
  margin: PropTypes.string,
};

export default HorizontalDivider;
