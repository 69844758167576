import { FormGroup, Label, Input } from 'reactstrap';

export const CustomCheckBox = ({
  checked = null,
  name = 'name',
  onChange,
  label = '',
  disabled = false,
  defaultChecked = null,
  labelStyle = null,
}) => {
  return (
    <div>
      <FormGroup check>
        <Label check>
          <Input
            disabled={disabled}
            type='checkbox'
            checked={checked}
            name={name}
            defaultChecked={defaultChecked}
            onChange={onChange}
          />
          <span className='form-check-sign'>
            <span className='check' /> <p style={labelStyle}>{label}</p>
          </span>
        </Label>
      </FormGroup>
    </div>
  );
};
