import React, { useContext, useState } from 'react';
import { UserContext } from '../../../context';
import { Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useQuery } from '@apollo/client';
import { selectStyles, handleAxiosErrorSwal } from 'helpers';
import { COMMENTS_BY } from '../Rating/RatingOffice';
import Select from 'react-select';
import { useParlors, useToggle } from '../../../hooks/gql.hooks';
import { GADGET_CATEGORY } from '../../../helpers/enum.helpers';
import { GADGET_NAME } from 'services/gql/index.jsx';
import Swal from 'sweetalert2';
import Loading from 'components/Loading';

const AddGadget = ({
  harmonization = false,
  price = 0,
  parlorStoreId = null,
  toggelOpen = null,
  count = 1,
}) => {
  const parlors = useParlors();
  const [loading, setLoading] = useState(false);
  const { data: gadgetsData, refetch } = useQuery(GADGET_NAME);
  const { data: commentsBy } = useQuery(COMMENTS_BY);
  const usersOption = commentsBy?.user?.map((el) => {
    return {
      label: `${el.first_name} ${el.last_name}`,
      value: el.id,
    };
  });
  const gadgetOptions = gadgetsData?.gadget?.map((el) => {
    return {
      label: el.name,
      value: el.id,
    };
  });
  const { api_v2, user } = useContext(UserContext);
  const [isOpenModal, toggleOpenModal] = useToggle(false);
  const [inputValues, setInputValues] = useState({
    description: '',
    quantity: count,
    serial_number: '',
    updated_by: user.id,
    user: null,
    gadget: null,
    status: '',
    estimated_cost: price,
    harmonization: harmonization,
    guarantee_date: null,
    category: null,
    parlor: null,
    password: null,
    guarantee_file: '',
  });
  const onSubmit = async () => {
    const data = new FormData();
    [...inputValues.guarantee_file].forEach((file) => {
      data.append('file', file, file.name);
    });
    setLoading(true);
    inputValues.description && data.append('description', inputValues.description);
    inputValues.serial_number && data.append('serial_number', inputValues.serial_number);
    inputValues.updated_by && data.append('updated_by', inputValues.updated_by);
    inputValues.user && data.append('user', inputValues.user);
    inputValues.gadget && data.append('gadget', inputValues.gadget);
    inputValues.status && data.append('status', inputValues.status);
    inputValues.estimated_cost &&
      data.append('estimated_cost', parseFloat(inputValues.estimated_cost)?.toFixed(2));
    inputValues.harmonization && data.append('harmonization', inputValues.harmonization);
    inputValues.guarantee_date && data.append('guarantee_date', inputValues.guarantee_date);
    inputValues.quantity && data.append('quantity', inputValues.quantity);
    inputValues.category && data.append('category', inputValues.category);
    inputValues.parlor && data.append('parlor', inputValues.parlor);
    inputValues.password && data.append('password', inputValues.password);
    parlorStoreId && data.append('parlorStoreId', parlorStoreId);
    api_v2
      .post(`/user-gadget/`, data)
      .then(() => {
        toggleOpenModal();
        refetch();
        setInputValues({
          description: '',
          quantity: 1,
          serial_number: '',
          updated_by: user.id,
          user: null,
          gadget: null,
          status: '',
          estimated_cost: price,
          harmonization: harmonization,
          guarantee_date: null,
          category: null,
          parlor: null,
          password: null,
          guarantee_file: '',
        });
        Swal.fire('', '', 'success');
        if (toggelOpen) {
          toggelOpen();
          Swal.fire('Устройство созданно и связанно', '', 'info');
        } else {
          Swal.fire('', '', 'success');
        }
        setInputValues({
          description: '',
          quantity: 1,
          serial_number: '',
          updated_by: user.id,
          user: null,
          gadget: null,
          status: '',
          estimated_cost: price,
          harmonization: harmonization,
          guarantee_date: null,
          category: null,
          parlor: null,
          password: null,
          guarantee_file: '',
        });
      })
      .catch(handleAxiosErrorSwal)
      .finally(() => setLoading(false));
  };

  const onHandleChange = (e) => setInputValues({ ...inputValues, [e.target.name]: e.target.value });
  const onGadgetChange = (e) => {
    setInputValues({ ...inputValues, gadget: e.value });
  };
  const onStatusChange = (e) => {
    setInputValues({ ...inputValues, status: e.value });
  };
  const onUserChange = (e) => {
    setInputValues({ ...inputValues, user: e.value });
  };
  return (
    <div>
      <Button style={{ marginBottom: '50px' }} onClick={toggleOpenModal}>
        Добавить устройство
      </Button>
      <Modal isOpen={isOpenModal} toggle={toggleOpenModal}>
        {loading && <Loading />}
        <ModalHeader toggle={toggleOpenModal}>Добавить устройство</ModalHeader>
        <ModalBody style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          <Label>
            Кому выдается устройство
            <Select
              name='user'
              onChange={onUserChange}
              options={usersOption}
              styles={selectStyles(false)}
            />
          </Label>
          <Label>
            Тип устройства
            <Select
              name='gadget'
              onChange={onGadgetChange}
              options={gadgetOptions}
              styles={selectStyles(false)}
            />
          </Label>
          <Label>
            Состояние устройства
            <Select
              name='status'
              onChange={onStatusChange}
              options={[
                { label: 'Не подлежит ремонту', value: 'beyond_repair' },
                { label: 'Требует ремонта', value: 'repair' },
                { label: 'Нормальное', value: 'normal' },
                { label: 'Новое', value: 'new' },
                { label: 'В ремонте', value: 'repaired' },
              ]}
              styles={selectStyles(false)}
            />
          </Label>
          <Label>
            Категория
            <Select
              name='category'
              onChange={(e) => setInputValues({ ...inputValues, category: e.value })}
              options={GADGET_CATEGORY}
              styles={selectStyles(false)}
            />
          </Label>
          <Label>
            Салон
            <Select
              name='parlor'
              onChange={(e) => setInputValues({ ...inputValues, parlor: e.value })}
              options={parlors}
              styles={selectStyles(false)}
            />
          </Label>
          {price ? null : (
            <Label>
              Оценочная стоимость
              <Input
                type='string'
                defaultValue={price}
                name='estimated_cost'
                onChange={onHandleChange}
              />
            </Label>
          )}

          <Label>
            Колличество
            <Input
              type='number'
              name='quantity'
              defaultValue={count}
              onChange={onHandleChange}
              disabled={count > 1}
            />
          </Label>
          <Label>
            Пароль
            <Input type='string' name='password' onChange={onHandleChange} />
          </Label>
          <Label>
            Описание устройства
            <Input type='textArea' name='description' onChange={onHandleChange} />
          </Label>
          <Label>
            Серийный номер
            <Input type='text' name='serial_number' onChange={onHandleChange} />
          </Label>
          <Label>
            Дата гарантии
            <Input type='date' name='guarantee_date' onChange={onHandleChange} />
          </Label>
          <Label>
            Файл гарантии
            <Input
              type='file'
              name='guarantee_file'
              onChange={(e) => setInputValues({ ...inputValues, guarantee_file: e.target.files })}
            />
          </Label>
        </ModalBody>
        <ModalFooter>
          <Button color='primary' style={{ width: '40%' }} onClick={onSubmit}>
            Зарегестрировать
          </Button>
          <Button onClick={toggleOpenModal} color='warning' style={{ width: '40%' }}>
            Закрыть
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
export default AddGadget;
